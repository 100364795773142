<template>
  <vue-final-modal
    class="affiliate-program-modal"
    :modelValue="isShowModal"
    @update:modelValue="toggleModal"
    :clickToClose="true"
    :overlayTransition="{ mode: 'in-out', duration: 200 }"
    :contentTransition="{ mode: 'in-out', duration: 200 }"
  >
    <div class="scroll">
      <div class="header">
        <button-modal-close @close="toggleModal(false)" />
        <div class="background-img-skeleton" v-if="isShowSkeleton" />
        <atomic-image
          @imageLoaded="isShowSkeleton = false"
          class="background"
          :class="{ 'isLoaded': !isShowSkeleton }"
          :src="getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.backgroundImg')" />
      </div>

      <div class="main">
        <div class="title">
          {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.title') }}
        </div>
        <div class="description">
          {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.description') }}
        </div>
      </div>

      <div class="actions">
        <button-base
          type="primary"
          size="md"
          @click="openLink('link')"
        >
          {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.becomePartner') }}
        </button-base>

        <button-base
          v-if="profile"
          type="ghost"
          size="md"
          @click="dontShowAnymore"
        >
          <atomic-spinner :is-shown="isUpdatingProfile" />
          {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.dontShowAnymore') }}
        </button-base>
      </div>

      <div class="bottom-web" v-if="!isTelegramUser">
        <div class="bottom-img" @click="openLink('webBottomLink')">
          <span class="bottom-img-label">
            {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.webBottomImgLabel') }}
          </span>
          <atomic-image class="image" :src="getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.webBottomImage')" />
        </div>
        <div class="bottom-link">
          <button-base
            type="ghost"
            size="md"
            @click="openLink('webBottomLink')"
          >
            <atomic-icon id="telegram" />
            {{ getContent(popupsData, defaultLocalePopupsData, 'promo.affiliateProgram.webBottomLinkText') }}
          </button-base>
        </div>
      </div>
    </div>

  </vue-final-modal>
</template>

<script setup lang="ts">
  import { VueFinalModal } from 'vue-final-modal';
  import { storeToRefs } from 'pinia';

  defineProps<{
    isShowModal: {
      type: Boolean,
      required: true
    }
  }>();

  const profileStore = useProfileStore();
  const { setProfileData } = profileStore;
  const { profile, isTelegramUser } = storeToRefs(profileStore);
  const { getContent } = useProjectMethods();
  const { changeProfileData } = useCoreProfileApi();
  const globalStore = useGlobalStore();


  const {
    popupsData,
    defaultLocalePopupsData
  } = storeToRefs(globalStore);

  const emit = defineEmits(['update:isShowModal']);

  const isUpdatingProfile = ref(false);
  const isShowSkeleton = ref(true);

  const toggleModal = (newValue) => {
    isUpdatingProfile.value = false;
    emit('update:isShowModal', newValue);
  };

  const openLink = (contentPath: string): void => {
    window.open(getContent(popupsData?.value, defaultLocalePopupsData?.value, `promo.affiliateProgram.${contentPath}`), '_blank');
    toggleModal(false);
  }

  const dontShowAnymore = async (): Promise<void> => {
    isUpdatingProfile.value = true;
    const formData = {
      enabledPromoModal: false
    };

    try {
      const submitResult = await changeProfileData(formData);
      setProfileData(submitResult);
      useEvent('profileUpdated');
      toggleModal(false);
    } catch (e) {
      console.log('Error', e);
    } finally {
      toggleModal(false);
    }
  };

</script>

<style src="~/assets/styles/components/modal/affiliate-program.scss" lang="scss" />

